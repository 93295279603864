import { useEffect } from 'react';

import { isLocalStorageAvailable } from '~/common/sentry-utilities';
import { useToast } from '~/providers/Toast';

import { refetchPinnedAppsQuery, usePinnedAppsQuery } from './queries/PinnedApps.query.generated';
import { usePinAppMutation, useUnpinAppMutation } from './queries/UserAppPins.mutation.generated';

const areProjectsSyncedKey = '@expo/useLocalStorage/are-projects-synced';

export function usePinnedApps(accountName: string) {
  const toast = useToast();

  const { data, loading } = usePinnedAppsQuery();
  const pinnedApps =
    data?.meUserActor?.pinnedApps.filter((app) => app.ownerAccount.name === accountName) ?? [];

  const [pinApp] = usePinAppMutation({
    refetchQueries: [refetchPinnedAppsQuery()],
    awaitRefetchQueries: true,
  });
  const [unpinApp] = useUnpinAppMutation({
    refetchQueries: [refetchPinnedAppsQuery()],
    awaitRefetchQueries: true,
  });

  const setIsPinned = (id: string) => async (shouldBePinned: boolean) => {
    try {
      if (shouldBePinned) {
        await pinApp({ variables: { appId: id } });
      } else {
        await unpinApp({ variables: { appId: id } });
      }
    } catch (error) {
      toast.add({
        message: (error as Error).message,
        theme: 'danger',
        onClose: toast.remove,
      });
    }
  };

  // Clean up local storage flag. Next step @tchayen: remove this.
  useEffect(() => {
    if (isLocalStorageAvailable() && localStorage.getItem(areProjectsSyncedKey)) {
      localStorage.removeItem(areProjectsSyncedKey);
    }
  }, []);

  return { pinnedApps, setIsPinned, loading };
}
