// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import { BackgroundJobReceiptDataFragmentDoc } from './BackgroundJobReceiptData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBackgroundJobReceiptByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetBackgroundJobReceiptByIdQuery = { __typename?: 'RootQuery', backgroundJobReceipt: { __typename?: 'BackgroundJobReceiptQuery', byId: { __typename?: 'BackgroundJobReceipt', id: string, state: Types.BackgroundJobState, tries: number, willRetry: boolean, resultId?: string | null, resultType: Types.BackgroundJobResultType, resultData?: any | null, errorCode?: string | null, errorMessage?: string | null, createdAt: any, updatedAt: any } } };


export const GetBackgroundJobReceiptByIdDocument = gql`
    query GetBackgroundJobReceiptById($id: ID!) {
  backgroundJobReceipt {
    byId(id: $id) {
      ...BackgroundJobReceiptData
    }
  }
}
    ${BackgroundJobReceiptDataFragmentDoc}`;
export function useGetBackgroundJobReceiptByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables> & ({ variables: GetBackgroundJobReceiptByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>(GetBackgroundJobReceiptByIdDocument, options);
      }
export function useGetBackgroundJobReceiptByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>(GetBackgroundJobReceiptByIdDocument, options);
        }
export function useGetBackgroundJobReceiptByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>(GetBackgroundJobReceiptByIdDocument, options);
        }
export type GetBackgroundJobReceiptByIdQueryHookResult = ReturnType<typeof useGetBackgroundJobReceiptByIdQuery>;
export type GetBackgroundJobReceiptByIdLazyQueryHookResult = ReturnType<typeof useGetBackgroundJobReceiptByIdLazyQuery>;
export type GetBackgroundJobReceiptByIdSuspenseQueryHookResult = ReturnType<typeof useGetBackgroundJobReceiptByIdSuspenseQuery>;
export type GetBackgroundJobReceiptByIdQueryResult = Apollo.QueryResult<GetBackgroundJobReceiptByIdQuery, GetBackgroundJobReceiptByIdQueryVariables>;
export function refetchGetBackgroundJobReceiptByIdQuery(variables: GetBackgroundJobReceiptByIdQueryVariables) {
      return { query: GetBackgroundJobReceiptByIdDocument, variables: variables }
    }