// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
export type BackgroundJobReceiptDataFragment = { __typename?: 'BackgroundJobReceipt', id: string, state: Types.BackgroundJobState, tries: number, willRetry: boolean, resultId?: string | null, resultType: Types.BackgroundJobResultType, resultData?: any | null, errorCode?: string | null, errorMessage?: string | null, createdAt: any, updatedAt: any };

export const BackgroundJobReceiptDataFragmentDoc = gql`
    fragment BackgroundJobReceiptData on BackgroundJobReceipt {
  id
  state
  tries
  willRetry
  resultId
  resultType
  resultData
  errorCode
  errorMessage
  createdAt
  updatedAt
}
    `;