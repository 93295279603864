import { TableCell } from '~/ui/components/Table/TableCell';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { Tag } from '~/ui/components/Tag';

export function ProjectRowSkeleton() {
  return (
    <>
      <TableSeparator />
      <TableCell className="flex h-16 items-center gap-3">
        <div className="size-8 rounded-md bg-element" />
        <div className="h-4 w-32 rounded-md bg-element" />
      </TableCell>
      <TableCell>
        <Tag small className="w-16" />
      </TableCell>
      <TableCell>
        <div className="h-4 w-32 rounded-md bg-element" />
      </TableCell>
    </>
  );
}
