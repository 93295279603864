import { mergeClasses } from '@expo/styleguide';
import type { ReactNode } from 'react';

type IconBoxedProps = {
  icon?: ReactNode;
  className?: string;
  theme?: 'default' | 'secondary';
};

export function IconBoxed({ icon, className, theme = 'default' }: IconBoxedProps) {
  return icon ? (
    <div
      className={mergeClasses(
        'flex content-center items-center rounded-md bg-element p-2',
        theme === 'secondary' && 'border border-default bg-overlay',
        className
      )}>
      {icon}
    </div>
  ) : null;
}
