import { ComponentType, HTMLAttributes, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { LoggedInProps } from '~/scenes/_app/helpers';
import { AccountDisabledBanner } from '~/ui/components/Banners/AccountDisabledBanner';
import { PageHeader } from '~/ui/components/PageHeader';
import { SidebarNavigation } from '~/ui/components/SidebarNavigation/SidebarNavigation';
import SidebarLayout from '~/ui/patterns/layouts/Sidebar';

import { ContentSpotlight } from './ContentSpotlight';

type Props = PropsWithChildren<{
  accountName: string;
  currentUser: LoggedInProps['currentUser'];
  currentDateAsString: string;
  showContentSpotlight?: boolean;
  title?: ReactNode;
  description?: string;
  icon?: ComponentType<HTMLAttributes<SVGSVGElement>>;
  rightSlot?: ReactNode;
  topSlot?: ReactElement;
}>;

export function DashboardLayout({
  accountName,
  currentUser,
  currentDateAsString,
  children,
  showContentSpotlight = false,
  title,
  description,
  rightSlot,
  topSlot,
  icon,
}: Props) {
  return (
    <SidebarLayout
      sidebar={
        <SidebarNavigation
          accountName={accountName}
          currentUser={currentUser}
          currentDateAsString={currentDateAsString}
        />
      }>
      <AccountDisabledBanner currentUser={currentUser} />
      {title && (
        <PageHeader title={title} description={description} rightSlot={rightSlot} Icon={icon} />
      )}
      <div className="flex w-full flex-1">
        <div className="mx-auto grid w-full max-w-screen-xl-gutters gap-6 p-6">
          {topSlot}
          <div className="grid w-full gap-10">
            {children}
            {showContentSpotlight && <ContentSpotlight />}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
}
