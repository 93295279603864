import { mergeClasses } from '@expo/styleguide';
import type { PropsWithChildren } from 'react';

export function ContentTimelineContentContainer({ children }: PropsWithChildren) {
  return (
    <div className="relative mb-1 ml-[19px] border-l border-l-secondary pl-4">
      {children}
      <div
        className={mergeClasses(
          'absolute -left-3 bottom-0 h-12 w-6 bg-gradient-to-b from-transparent to-default',
          'dark:to-screen'
        )}
      />
    </div>
  );
}
