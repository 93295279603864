import { LinkBase } from '@expo/styleguide';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { Pin02Icon } from '@expo/styleguide-icons/outline/Pin02Icon';
import { Settings01Icon } from '@expo/styleguide-icons/outline/Settings01Icon';
import { Trash03Icon } from '@expo/styleguide-icons/outline/Trash03Icon';
import { Pin02SolidIcon } from '@expo/styleguide-icons/solid/Pin02SolidIcon';
import { useState } from 'react';

import { getProjectURL } from '~/common/helpers';
import { AppDataWithRepoFragment } from '~/graphql/types.generated';
import { ProjectDeleteDialog } from '~/scenes/ProjectSettingsScene/ProjectDeleteDialog';
import { Button } from '~/ui/components/Button';
import { Dropdown } from '~/ui/components/Dropdown';
import { DropdownItem } from '~/ui/components/Dropdown/DropdownItem';
import { ProjectIcon } from '~/ui/components/ProjectIcon';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { LABEL, CAPTION } from '~/ui/components/text';

type Props = {
  accountName: string;
  hasOwnerRole: boolean;
  app: AppDataWithRepoFragment;
  setIsPinned?: (isPinned: boolean) => void;
  isPinned?: boolean;
  isPinningDisabled?: boolean;
};

export function ProjectRow({
  accountName,
  hasOwnerRole,
  app,
  isPinned,
  setIsPinned,
  isPinningDisabled,
}: Props) {
  const [isProjectDeleteDialogOpen, setIsProjectDeleteDialogOpen] = useState(false);

  return (
    <>
      <TableSeparator />
      <TableRow>
        <LinkBase href={getProjectURL(accountName, app.slug)}>
          <TableCell
            theme="interactive"
            className="flex h-14 flex-1 flex-col justify-center gap-3 overflow-hidden">
            <div className="flex items-center gap-3">
              <ProjectIcon name={app.name} iconUrl={app.icon?.url} isPinned={isPinned} />
              <div className="grid">
                <LABEL className="!leading-snug" variant="oneLine">
                  {app.name}
                </LABEL>
                {app.githubRepository && (
                  <CAPTION
                    tag="span"
                    variant="oneLine"
                    theme="secondary"
                    className="inline-flex items-center gap-1">
                    <GithubIcon className="icon-xs shrink-0 text-icon-secondary" />
                    <span className="truncate">
                      {app.githubRepository?.metadata.githubRepoOwnerName}/
                      {app.githubRepository?.metadata.githubRepoName}
                    </span>
                  </CAPTION>
                )}
              </div>
            </div>
          </TableCell>
        </LinkBase>
        <TableCell>
          <TableCellText variant="oneLine">{app.slug}</TableCellText>
        </TableCell>
        <TableCell>
          <Dropdown
            className="min-w-[180px]"
            trigger={
              <Button theme="quaternary" leftSlot={<DotsVerticalIcon className="icon-md" />} />
            }>
            <DropdownItem
              label={isPinned ? 'Unpin project' : 'Pin project'}
              Icon={isPinned ? Pin02SolidIcon : Pin02Icon}
              onSelect={() => {
                if (!setIsPinned || isPinningDisabled) {
                  return;
                }
                setIsPinned(!isPinned);
              }}
            />
            <DropdownItem
              label="Project settings"
              Icon={Settings01Icon}
              href={getProjectURL(accountName, app.slug, 'settings')}
            />
            <DropdownItem
              label="Delete project"
              disabled={!hasOwnerRole}
              onSelect={() => {
                setIsProjectDeleteDialogOpen(true);
              }}
              Icon={Trash03Icon}
              destructive
            />
          </Dropdown>
        </TableCell>
      </TableRow>
      <ProjectDeleteDialog
        app={app}
        open={isProjectDeleteDialogOpen}
        onClose={() => {
          setIsProjectDeleteDialogOpen(false);
        }}
      />
    </>
  );
}
