// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { BackgroundJobReceiptDataFragmentDoc } from '../../scenes/Dashboard/Projects/BuildsListScene/queries/BackgroundJobReceiptData.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduleAppDeletionMutationVariables = Types.Exact<{
  appId: Types.Scalars['ID']['input'];
}>;


export type ScheduleAppDeletionMutation = { __typename?: 'RootMutation', app?: { __typename?: 'AppMutation', scheduleAppDeletion: { __typename?: 'BackgroundJobReceipt', id: string, state: Types.BackgroundJobState, tries: number, willRetry: boolean, resultId?: string | null, resultType: Types.BackgroundJobResultType, resultData?: any | null, errorCode?: string | null, errorMessage?: string | null, createdAt: any, updatedAt: any } } | null };


export const ScheduleAppDeletionMutationDocument = gql`
    mutation ScheduleAppDeletionMutation($appId: ID!) {
  app {
    scheduleAppDeletion(appId: $appId) {
      id
      ...BackgroundJobReceiptData
    }
  }
}
    ${BackgroundJobReceiptDataFragmentDoc}`;
export function useScheduleAppDeletionMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleAppDeletionMutation, ScheduleAppDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleAppDeletionMutation, ScheduleAppDeletionMutationVariables>(ScheduleAppDeletionMutationDocument, options);
      }
export type ScheduleAppDeletionMutationHookResult = ReturnType<typeof useScheduleAppDeletionMutation>;
export type ScheduleAppDeletionMutationMutationResult = Apollo.MutationResult<ScheduleAppDeletionMutation>;
export type ScheduleAppDeletionMutationMutationOptions = Apollo.BaseMutationOptions<ScheduleAppDeletionMutation, ScheduleAppDeletionMutationVariables>;