import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';

import { Button } from '~/ui/components/Button';

type Props = {
  title: string;
  href: string;
};

export function ContentTimelineViewAllButton({ title, href }: Props) {
  return (
    <Button
      skipNextLink={href.includes('/blog')}
      theme="quaternary"
      rightSlot={<ArrowRightIcon />}
      href={href}>
      {title}
    </Button>
  );
}
