import { LinkBase } from '@expo/styleguide';

import { CALLOUT, HEADLINE } from '~/ui/components/text';

import { ContentTimelineItemImage } from './ContentTimelineItemImage';
import { ContentTimelineItemIndicator } from './ContentTimelineItemIndicator';

type Props = {
  title: string;
  href: string;
  date: string;
  src?: string;
  openInNewTab?: boolean;
};

export function ContentTimelineItem({ src, title, date, href, openInNewTab = false }: Props) {
  return (
    <LinkBase
      href={href}
      skipNextLink={href.includes('/blog')}
      openInNewTab={openInNewTab}
      className="relative -top-3 mb-2.5 flex gap-4 rounded-lg px-3 py-1.5 transition-colors hocus:bg-hover">
      {src && <ContentTimelineItemImage src={src} alt="Entry preview image" />}
      <div>
        <HEADLINE>{title}</HEADLINE>
        <CALLOUT theme="secondary" tag="time">
          {new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </CALLOUT>
      </div>
      <ContentTimelineItemIndicator />
    </LinkBase>
  );
}
