import { ReactElement } from 'react';

import { IconBoxed } from '~/ui/components/IconBoxed';
import { H3 } from '~/ui/components/text';

type Props = {
  icon: ReactElement;
  title: string;
};

export function ContentTimelineTitle({ icon, title }: Props) {
  return (
    <H3 className="mb-7 flex items-center gap-3">
      <IconBoxed icon={icon} theme="secondary" />
      {title}
    </H3>
  );
}
