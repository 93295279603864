import type { ReactNode } from 'react';

import { CALLOUT, H3 } from '~/ui/components/text';

import { SuccessCheckmark } from '../SuccessCheckmark';

type Props = {
  title: string;
  description?: ReactNode;
};

export function DialogSuccessContent({ title, description }: Props) {
  return (
    <div className="flex flex-col items-center justify-center gap-2 px-6 py-10 text-center">
      <SuccessCheckmark size="sm" className="mb-2" />
      <H3 weight="medium">{title}</H3>
      {description && (
        <CALLOUT className="max-w-[36ch]" theme="secondary">
          {description}
        </CALLOUT>
      )}
    </div>
  );
}
