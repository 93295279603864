import { theme } from '@expo/styleguide';

export function PlaceholderIllustration() {
  return (
    <svg
      className="mt-6 stroke-palette-gray7"
      width="143"
      height="128"
      viewBox="0 0 143 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M16.501 0.2547V127.255" />
      <path d="M44.002 0.2547V127.255" />
      <path d="M71.5039 0.2547V127.255" />
      <path d="M99.0049 0.2547V127.255" />
      <path d="M126.506 0.2547V127.255" />
      <path d="M0.892578 63.7547L142.108 63.7547" />
      <path d="M0.892578 38.588L142.108 38.588" />
      <path d="M0.892578 88.9214L142.108 88.9214" />
      <path d="M0.892578 114.088L142.108 114.088" />
      <path d="M0.892578 13.4214L142.108 13.4214" />
      <rect x="1" width="141" height="127" fill="url(#gradient)" stroke="none" />
      <defs>
        <linearGradient
          id="gradient"
          x1="71.5"
          y1="0"
          x2="71.5"
          y2="127"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={theme.background.default} stopOpacity="0" />
          <stop offset="1" stopColor={theme.background.default} />
        </linearGradient>
      </defs>
    </svg>
  );
}
