import NextImage from 'next/image';

import { OptimizedImage } from '~/ui/components/OptimizedImage';

type Props = {
  src: string;
  alt: string;
};

export function ContentTimelineItemImage({ src, alt }: Props) {
  const srcHasImage = Boolean(
    src?.match(/^(.*?).(png|jpg|jpeg|webp|avif)(.*?)$/gi) ?? src?.includes('cdn-images')
  );

  if (srcHasImage) {
    return (
      <NextImage
        src={src ?? ''}
        height={40}
        width={40}
        className="mt-[5px] max-h-[40px] min-h-[40px] min-w-[40px] rounded-md object-cover shadow-xs"
        alt={alt ?? 'Item image'}
      />
    );
  }

  return (
    <OptimizedImage
      src="/static/images/blog-placeholder.png"
      height={40}
      width={40}
      className="mt-[5px] max-h-[40px] min-h-[40px] min-w-[40px] rounded-md object-cover shadow-xs"
      alt={alt ?? 'Item image'}
    />
  );
}
