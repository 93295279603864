// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PinAppMutationVariables = Types.Exact<{
  appId: Types.Scalars['ID']['input'];
}>;


export type PinAppMutation = { __typename?: 'RootMutation', userAppPins: { __typename?: 'UserAppPinMutation', pinApp: string } };

export type UnpinAppMutationVariables = Types.Exact<{
  appId: Types.Scalars['ID']['input'];
}>;


export type UnpinAppMutation = { __typename?: 'RootMutation', userAppPins: { __typename?: 'UserAppPinMutation', unpinApp?: string | null } };


export const PinAppDocument = gql`
    mutation PinApp($appId: ID!) {
  userAppPins {
    pinApp(appId: $appId)
  }
}
    `;
export function usePinAppMutation(baseOptions?: Apollo.MutationHookOptions<PinAppMutation, PinAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinAppMutation, PinAppMutationVariables>(PinAppDocument, options);
      }
export type PinAppMutationHookResult = ReturnType<typeof usePinAppMutation>;
export type PinAppMutationResult = Apollo.MutationResult<PinAppMutation>;
export type PinAppMutationOptions = Apollo.BaseMutationOptions<PinAppMutation, PinAppMutationVariables>;
export const UnpinAppDocument = gql`
    mutation UnpinApp($appId: ID!) {
  userAppPins {
    unpinApp(appId: $appId)
  }
}
    `;
export function useUnpinAppMutation(baseOptions?: Apollo.MutationHookOptions<UnpinAppMutation, UnpinAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinAppMutation, UnpinAppMutationVariables>(UnpinAppDocument, options);
      }
export type UnpinAppMutationHookResult = ReturnType<typeof useUnpinAppMutation>;
export type UnpinAppMutationResult = Apollo.MutationResult<UnpinAppMutation>;
export type UnpinAppMutationOptions = Apollo.BaseMutationOptions<UnpinAppMutation, UnpinAppMutationVariables>;